<template>
	<footer class="footer">
		<div :class="[footerBG, {'p-4 text-white':true}]">
			<div class="d-flex justify-content-between flex-column flex-lg-row">
				<div class="mb-3 mb-lg-0">
					<ul class="list-inline mb-0 d-lg-block d-flex flex-column flex-lg-row">
						<li class="list-inline-item mb-2 mb-md-0">
							<a href="tel:22341333" class="text-white">
								<img v-lazy="imagePhone" :class="{'mr-2':true, 'img-white':footerBG=='bg-primary'}" width="18" height="18" alt="Teléfono" v-if="imagePhone">
								2234-1333
							</a>
						</li>
						<li class="list-inline-item mb-2 mb-md-0">
							<a href="https://api.whatsapp.com/send?phone=+50222341368&text=Quiero%20m%C3%A1s%20informaci%C3%B3n" target="_blank" class="text-white">
								<img v-lazy="imageWhatsapp" :class="{'mr-2':true, 'img-white':footerBG=='bg-primary'}" width="18" height="18" alt="WhatsApp" v-if="imageWhatsapp">
								2234-1368
							</a>
						</li>
						<li class="list-inline-item mb-2 mb-md-0">
							<a href="mailto:info@clubcashin.com?subject=Quiero%20m%C3%A1s%20informaci%C3%B3n&body=Hola%2C%20Quiero%20m%C3%A1s%20informaci%C3%B3n." class="text-white">
								<img v-lazy="imageCorreo" :class="{'mr-2':true, 'img-white':footerBG=='bg-primary'}" width="18" height="12" alt="Correo" v-if="imageCorreo">
								info@clubcashin.com
							</a>
						</li>
						<li class="list-inline-item mb-2 mb-md-0">
							<a href="https://goo.gl/maps/gcTBPC1mfs1WQ5dp9" class="text-white" target="_blank">
								<img v-lazy="imageUbicacion" :class="{'mr-2':true, 'img-white':footerBG=='bg-primary'}" width="15" height="21" alt="Ubicación" v-if="imageUbicacion">
								3a avenida “A” 13-78, Colonia Lomas de Pamplona zona 13
							</a>
						</li>
						<li class="list-inline-item d-none d-lg-inline">
							|
						</li>
						<li class="list-inline-item mb-2 mb-md-0">
							<router-link :to="{name:'terminos.index'}" class="text-white underline">
								<u>Términos y condiciones</u>
							</router-link>
						</li>
						<li class="list-inline-item d-none d-lg-inline">
							|
						</li>
						<li class="list-inline-item mb-2 mb-md-0">
							<router-link :to="{name:'privacidad.index'}" class="text-white underline">
								<u>Aviso de privacidad</u>
							</router-link>
						</li>
					</ul>
				</div>
				<div class="d-flex justify-content-center text-right">
					<div class="mx-1">
						<a href="https://www.instagram.com/clubcashin/" target="_blank">
							<img v-lazy="imageInstagram" :class="{'mr-1':true, 'img-white':footerBG=='bg-primary'}" width="25" height="25" alt="Instagram" v-if="imageInstagram">
						</a>
					</div>
					<div class="mx-1">
						<a href="https://www.facebook.com/Clubcashin/" target="_blank">
							<img v-lazy="imageFacebook" :class="{'mr-1':true, 'img-white':footerBG=='bg-primary'}" width="25" height="25" alt="Facebook" v-if="imageFacebook">
						</a>
					</div>
					<div class="mx-1">
						<a href="https://www.linkedin.com/company/clubcashin-com" target="_blank">
							<img v-lazy="imageLinkedin" :class="{'mr-1':true, 'img-white':footerBG=='bg-primary'}" width="25" height="25" alt="Linkedin" v-if="imageLinkedin">
						</a>
					</div>
				</div>
			</div>
		</div>
		<div class="row m-0 bg-white Quicksand">
			<div class="col-lg-5 mb-3 mb-lg-0 borderr">
				<a href="https://www.lei-worldwide.com/" target="_blank">
					<div class="d-flex flex-column flex-lg-row align-items-center h-100 p-2 justify-content-center">
						<span class="mr-2 font-weight-600">
							Certificación
							<br class="d-lg-block d-none">
							internacional
						</span>
						<div>
							<img v-lazy="imageLeiWorldwide" class="img-fluid" width="324" height="60" alt="LEI WORLD WIDE" v-if="imageLeiWorldwide">
						</div>
					</div>
				</a>
			</div>
			<div class="col-lg-7">
				<div class="form-row">
					<div class="col-lg-6 mb-3 mb-lg-0 borderr">
						<a href="https://www.qil4.com/" target="_blank">
							<div class="d-flex flex-column flex-lg-row align-items-center h-100 p-2 justify-content-center">
								<span class="mr-2 font-weight-600">
									Asesoría <br class="d-lg-block d-none">
									estructura <br class="d-lg-block d-none">
									legal:
								</span>
								<div>
									<img v-lazy="imageQilAbogados" class="img-fluid" width="200" height="60" alt="QIL +4 ABOGADOS" v-if="imageQilAbogados">
								</div>
							</div>
						</a>
					</div>
					<div class="col-lg-6 mb-3 mb-lg-0">
						<a href="https://www.guatemalafintech.com/" target="_blank">
							<div class="d-flex flex-column flex-lg-row align-items-center h-100 p-2 justify-content-center">
								<span class="mr-2 font-weight-600">
									Miembro de:
								</span>
								<div>
									<img v-lazy="imageFintechGuatemala" class="img-fluid" width="179" height="60" alt="Fintech Guatemala" v-if="imageFintechGuatemala">
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>
<script type="text/javascript">
	export default {
		data(){
			return {
				imagePhone: require('@/assets/image/phone.webp'),
				imageWhatsapp: require('@/assets/image/whatsapp.webp'),
				imageCorreo: require('@/assets/image/correo.webp'),
				imageUbicacion: require('@/assets/image/ubicacion.webp'),

				imageInstagram: require('@/assets/image/instagram.webp'),
				imageLinkedin: require('@/assets/image/linkedin.webp'),
				imageFacebook: require('@/assets/image/facebook.webp'),

				imageLeiWorldwide: require('@/assets/image/lei_worldwide.webp'),
				imageQilAbogados: require('@/assets/image/qil_abogados.webp'),
				imageFintechGuatemala: require('@/assets/image/fintech_guatemala.webp')
			}
		},
		computed: {
			footerBG(){
				return this.$store.getters.footerBG
			},
		}
	}
</script>