<template>
	<header>
		<nav class="navbar navbar-expand-xl navbar-dark bg-primary px-4 fixed-top" style="padding-top: 2.5rem !important; padding-bottom: 2.5rem !important;">
			<router-link :to="{name:'Home'}" class="navbar-brand" onclick="$('.navbar-collapse').collapse('hide')">
				<img src="@/assets/image/logo.webp" width="182" height="30" alt="Logo" loading="lazy">
			</router-link>
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
				<span class="material-icons">close</span>
			</button>
			<button class="navbar-toggler" type="button" v-on:click.prevent="mobileMenuHandler" v-if="false">
				<span class="navbar-toggler-icon"></span>
				<span class="material-icons">close</span>
			</button>
			<div class="collapse navbar-collapse" id="navbarNavAltMarkup">
				<div class="navbar-nav ml-auto">
					<div class="nav-item dropdown">
						<a href="#" :class="{'nav-link dropdown-toggle':true, 'active':validateInicio=='Home' || validateInicio == 'contacto.index'}" id="navbarDropdownHome" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							Sobre nosotros
						</a>
						<div class="dropdown-menu overflow-hidden" aria-labelledby="navbarDropdownHome">
							<div class="dropdown-item">
								<router-link :to="{name:'Home'}" class="nav-link" onclick="$('.navbar-collapse').collapse('hide')" active-class="active">
									Quiénes somos
								</router-link>
							</div>
							<div class="dropdown-item">
								<router-link :to="{name:'contacto.index'}" class="nav-link" onclick="$('.navbar-collapse').collapse('hide')" active-class="active" v-if="false">
									Testimonios
								</router-link>
							</div>
							<div class="dropdown-item">
								<router-link :to="{name:'contacto.index'}" class="nav-link" onclick="$('.navbar-collapse').collapse('hide')" active-class="active">
									Contáctanos
								</router-link>
							</div>
						</div>
					</div>

					<div class="nav-item dropdown">
						<a href="#" :class="{'nav-link dropdown-toggle':true, 'active':
							validateInicio == 'precalifique.index'
							|| validateInicio=='precalifique.auto-compras.index' 
							|| validateInicio=='precalifique.auto-compras-nuevos.index'
							
							|| validateInicio == 'precalifique.vehiculos.index'
						}" id="navbarDropdownPrestamos" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							Nuestros préstamos
						</a>
						<div class="dropdown-menu" aria-labelledby="navbarDropdownPrestamos">
							<div class="dropdown-item">
								<router-link :to="{name:'precalifique.index'}" :class="{'nav-link':true, 'active':validateInicio == 'precalifique.index' }" onclick="$('.navbar-collapse').collapse('hide')">
									Precalifique
								</router-link>
							</div>
							<div class="nav-item dropdown-item dropdown-submenu dropdown">
								<a href="#" :class="{'nav-link dropdown-toggle font-weight-normal':true, 'active':validateInicio=='precalifique.auto-compras.index' || validateInicio == 'contacto.index'  || validateInicio == 'contacto.index' || validateInicio == 'precalifique.auto-compras-nuevos.index'}" id="autocompras" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-on:click.prevent.stop="''">
									AutoCompras
								</a>
								<div class="dropdown-menu" aria-labelledby="autocompras">
									<div class="dropdown-item">
										<router-link :to="{name:'precalifique.auto-compras.index'}" class="nav-link" onclick="$('.navbar-collapse').collapse('hide')" exact-active-class="active">
											Precalifica seminuevos
										</router-link>
									</div>
									<div class="dropdown-item">
										<router-link :to="{name:'vehiculos.muro'}" class="nav-link" onclick="$('.navbar-collapse').collapse('hide')" exact-active-class="active">
											Marketplace seminuevos
										</router-link>
									</div>

									<div class="dropdown-item">
										<router-link :to="{name:'precalifique.auto-compras-nuevos.index'}" class="nav-link" onclick="$('.navbar-collapse').collapse('hide')" exact-active-class="active">
											Precalifica nuevos
										</router-link>
									</div>
									<div class="dropdown-item" v-if="false">
										<router-link :to="{name:'vehiculos.nuevos'}" class="nav-link" onclick="$('.navbar-collapse').collapse('hide')" exact-active-class="active">
											Marketplace nuevos
										</router-link>
									</div>
								</div>
							</div>
							<div class="nav-item dropdown-item dropdown-submenu dropdown">
								<a href="#" :class="{'nav-link dropdown-toggle font-weight-normal':true, 'active':validateInicio=='precalifique.vehiculos.index'}" id="sobrecarro" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" v-on:click.prevent.stop="''">
									SobreCarro
								</a>
								<div class="dropdown-menu" aria-labelledby="sobrecarro">
									<div class="dropdown-item">
										<router-link :to="{name:'precalifique.vehiculos.index'}" class="nav-link" onclick="$('.navbar-collapse').collapse('hide')" active-class="active">
											Precalifica
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="nav-item dropdown">
						<a href="#" :class="{'nav-link dropdown-toggle':true, 'active':validateInicio=='vehiculos.create' || validateInicio == 'vehiculos.muro' || validateInicio == 'vehiculos.nuevos'}" id="vendelo" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							Compra – Vende
						</a>
						<div class="dropdown-menu overflow-hidden" aria-labelledby="vendelo">
							<div class="dropdown-item">
								<router-link :to="{name:'vehiculos.create'}" class="nav-link" onclick="$('.navbar-collapse').collapse('hide')" active-class="active">
									Vende tu carro
								</router-link>
							</div>
							<div class="dropdown-item">
								<router-link :to="{name:'vehiculos.muro'}" class="nav-link" onclick="$('.navbar-collapse').collapse('hide')" exact-active-class="active">
									Marketplace seminuevos
								</router-link>
							</div>
							<div class="dropdown-item" v-if="false">
								<router-link :to="{name:'vehiculos.nuevos'}" class="nav-link" onclick="$('.navbar-collapse').collapse('hide')" exact-active-class="active" v-if="false">
									Marketplace nuevos
								</router-link>
							</div>
							<div class="dropdown-item" v-if="false">
								<router-link :to="{name:'contacto.index'}" class="nav-link" onclick="$('.navbar-collapse').collapse('hide')" active-class="active" v-if="false">
									Vende tu propiedad
								</router-link>
							</div>
						</div>
					</div>

					<div class="nav-item dropdown">
						<a href="#" :class="{'nav-link dropdown-toggle':true, 'active':validateInicio=='inversionista.index' || validateInicio=='opciones-inversion.index' || validateInicio=='pool.index' || validateInicio=='login'}" id="inversionista" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							Inversionista
						</a>
						<div class="dropdown-menu" aria-labelledby="inversionista">
							<div class="dropdown-item">
								<router-link :to="{name:'inversionista.index'}" class="nav-link" onclick="$('.navbar-collapse').collapse('hide')" active-class="active">
									Cómo funciona?
								</router-link>
							</div>

							<div class="dropdown-item">
								<router-link :to="{name:'opciones-inversion.index'}" class="nav-link" onclick="$('.navbar-collapse').collapse('hide')" active-class="active">
									Opciones de inversión
								</router-link>
							</div>

							<div class="dropdown-item">
								<router-link :to="{name:'pool.index'}" class="nav-link" onclick="$('.navbar-collapse').collapse('hide')" active-class="active">
									Pool
								</router-link>
							</div>

							<div class="dropdown-item">
								<router-link :to="{name:'login'}" class="nav-link" onclick="$('.navbar-collapse').collapse('hide')" active-class="active" v-if="!isLoggedIn">
									Regístrate
								</router-link>
							</div>
						</div>
					</div>

					<router-link :to="{name:'admin.index'}" class="nav-item nav-link border-inversion border rounded-pill" active-class="active" v-if="isLoggedIn && currentUser.prf == 3" onclick="$('.navbar-collapse').collapse('hide')">
						Admin
					</router-link>
					<router-link :to="{name:'perfil.index'}" class="nav-item nav-link border-inversion border rounded-pill" active-class="active" v-if="isLoggedIn && currentUser.prf != 3" onclick="$('.navbar-collapse').collapse('hide')">
						Perfil
					</router-link>
					<router-link :to="{name:'login'}" class="nav-item nav-link border-inversion border rounded-pill" active-class="active" v-if="!isLoggedIn" onclick="$('.navbar-collapse').collapse('hide')">
						Ingresar
					</router-link>
					<router-link  :to="{name:'terminos.index'}" class="nav-item nav-link" onclick="$('.navbar-collapse').collapse('hide')" v-if="isMobile">
						Términos y condiciones
					</router-link>
					<router-link  :to="{name:'logout'}" class="nav-item nav-link" onclick="$('.navbar-collapse').collapse('hide')" v-if="isMobile">
						Cerrar sesión
					</router-link>
				</div>
			</div>
		</nav>
		<a href="https://api.whatsapp.com/send?phone=+50222341368&text=Quiero%20m%C3%A1s%20informaci%C3%B3n" class="float" target="_blank">
			<img src="@/assets/image/whatsapp_white.webp" alt="WhatsApp" width="20" height="20">
		</a>
	</header>
</template>
<script type="text/javascript">
	export default {
		mounted(){
			/*$('.navbar a').on('click', function(){
				$('.navbar-collapse').collapse('hide');
	        });*/

	        $(document).ready(function(){
	        	$('.dropdown-menu a.dropdown-toggle').on('click', function() {
	        		if (!$(this).next().hasClass('show')) {
	        			$(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
	        		}

	        		var $subMenu = $(this).next('.dropdown-menu');
	        		$subMenu.toggleClass('show');
	        		$(this).parents('nav-item.dropdown.show').on('hidden.bs.dropdown', function() {
	        			$('.dropdown-submenu .show').removeClass('show');
	        		});
	        		$(this).parent().toggleClass('show')
	        		return false;
	        	});

				$('.navbar').on('show.bs.collapse', function () {
					$(this).addClass('open-collapse');
				}).on('hide.bs.collapse', function () {
					$(this).removeClass('open-collapse');
				});
			});
		},

		data(){
			return {
				menus: [
					{
						titulo: 'Quiero un préstamo/crédito',
						auth: false,
						movil: false,
						children:[{
							titulo: 'Préstamo por tu carro',
							descripcion: 'Préstamo con tu carro como garantía. Fácil, rápido y barato',
							ruta: 'requisitos.index'
						}]
					},
					{
						titulo:'Compra y vende carros',
						auth: false,
						movil: false,
						children: [{
							titulo: 'La mejor tienda online para comprar y vender carros, con opción a financiamiento',
							descripcion: ''
						}]
					},
					{
						titulo: 'Quiero invertir',
						auth: false,
						movil: false,
						children: [{
							titulo: 'Opciones de inversión',
							descripcion: 'Invierte tu dinero en préstamos que estarán respaldados por activos y genera mejores rendimientos.',
							ruta: 'opciones-inversion.index'
						},
						{
							titulo: 'Quiero conocer más',
							descripcion: 'El equipo de Clubcashin.com cuenta con más de 25 años de experiencia en préstamos e inversiones',
							ruta: 'requisitos.index'	
						},
						{
							titulo: '¡Nuevo! inversiones colectivas',
							ruta: 'pool.index'
						}]
					},
					{
						titulo: 'Club beneficios',
						auth: false,
						movil: false,
						children: [{
							titulo: 'Promociones y descuentos',
							descripcion: 'Promociones y descuentos exclusivos para nuestros usuarios. ¡Crea tu usuario gratis!',
						},
						{
							titulo: 'Afilia tu negocio',
							descripcion: 'Forma parta de nuestras empresas amigas y ofrece tus descuentos a todos nuestros usuarios',
							ruta: 'contacto.index'
						}]
					},
					{
						titulo: 'Sobre nosotros',
						auth: false,
						movil: false,
						children: [{
							titulo: 'Nosotros',
							descripcion: '¿Quiénes somos?',
							ruta: 'nosotros.index'
						},
						{
							titulo: 'Medios y prensa',
							descripcion: 'Lo que dicen los medios de nosotros',
							ruta: 'nosotros.index'	
						},
						{
							titulo: 'Preguntas frecuentes',
							descripcion: 'Lo que más nos preguntan nuestros usuarios',
							ruta: 'nosotros.index'	
						},
						{
							titulo: 'Reseñas de  nuestros clientes',
							descripcion: 'Las experiencias de nuestros clientes',
							ruta: 'nosotros.index'
						},
						{
							titulo: 'Blog',
							descripcion: '',
							ruta: ''
						},
						{
							titulo: 'Contáctanos',
							descripcion: '',
							ruta: 'contacto.index'
						},
						{
							titulo: 'Términos y condiciones',
							descripcion: '',
							ruta: 'terminos.index'
						}]
					},
					{
						titulo: 'Crea tu usuario',
						auth: false,
						movil: false,
						children: [{
							titulo: 'Crea tu usuario',
							descripcion: '',
							ruta: 'register'
						},
						{
							titulo: 'Ingresar',
							descripcion: '',
							ruta: 'login'
						}]
					},
					{
						titulo: 'Perfil',
						auth: true,
						movil: true,
						children: [{
							titulo: 'Cerrar sesión',
							ruta: 'logout'
						}]
					}
				]
			}
		},

		methods: {
			mobileMenuHandler(){
				this.$store.commit('mobileMenu', true)
			},
			mobileHideHandler(){
				$('.navbar-collapse').collapse('hide');
			}
		},

		computed: {
			isLoggedIn(){
				return this.$store.getters.isLoggedIn
			},
			isMobile() {
				return this.$store.getters.isMobile
			},
			mobileMenu(){
				return this.$store.getters.mobileMenu
			},
			windowWidth(){
				return this.$store.getters.windowWidth || 0
			},
			currentUser(){
				return this.$store.getters.currentUser
			},
			validateInicio(){
				if (this.$router.currentRoute) {
					return this.$router.currentRoute.value.name || ''
				}
				return ''
			},
			menusFiltered(){
				return this.menus.filter(x => !x.movil)
			},
		},

		watch: {
			windowWidth(val){
				if(val>=992){
					this.$store.commit('mobileMenu', false)
				}
			},
		}
	}
</script>