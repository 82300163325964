<template>
    <Preloader></Preloader>
    <div id="nav" v-if="!templateHide">
        <Menu />
    </div>
    <main :class="{'d-block position-relative h-100':true, 'mt-0':templateHide}">
        <div class="bg-light text-center p-1" v-if="!online">
            Sin conexión a internet
        </div>
        <router-view></router-view>
    </main>
    <Footer v-if="!templateHide" />
    <footer class="text-center p-2">
        <small>Copyright 2022: Cube investments S.A. Todos los Derechos Reservados</small>
    </footer>
</template>
<script type="text/javascript">
    import Menu from "@/components/Menu.vue"
    import Footer from "@/components/Footer.vue"
    import Preloader from "@/components/Preloader.vue"

    export default {
    name: 'app',
    components: {
      Menu,
      Footer,
      Preloader
    },
    computed: {
      online(){
        return this.$store.getters.online
      },
      templateHide(){
        return this.$route.meta.templateHide
      },
    }
  }
</script>