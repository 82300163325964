<template>
	<div id="loader-wrapper" class="bg-light" v-if="!isloaded">
		<div class="logo center">
			<div>
				<img v-lazy="imageLogoDark" class="img-fluid" width="200" height="200">
			</div>
			<div id="loader"></div>
		</div>
	</div>
</template>
<script type="text/javascript">
	export default {
		data(){
			return {
				isloaded: false,
				imageLogoDark: require('@/assets/image/logo_load.webp'),
			}
		},
		mounted(){
			document.onreadystatechange = () => {
				var mod = this
				if (document.readyState == "complete") {
					setTimeout(function(){
						mod.isloaded = true
					}, 50)
				}
			}
		},
	}
</script>